export const menuBody = [
    { treatment: "Back Massage", minutes: "30 - 45", price: "30 - 40" },
    { treatment: "Relaxing massage", minutes: "55 - 75", price: "45 - 55" },
    { treatment: "Deep tissue", minutes: "55 - 75", price: "50 - 60" },
    { treatment: "Le spa massage", minutes: "75 - 90", price: "55 - 70" },
    { treatment: "Lymphatic Drainage", minutes: "75", price: "60" },
    { treatment: "Magnesium support", minutes: "75", price: "60" },
    { treatment: "Hot Stone Massage", minutes: "85", price: "70" },
    { treatment: "Bamboo massage", minutes: "75", price: "60" },
    { treatment: "Le Spa Thai", minutes: "75", price: "60" },
    { treatment: "Mum to Be", minutes: "45", price: "45" },
    { treatment: "Scrub & Exfoliate", minutes: "40 - 75", price: "40 - 60" },
    { treatment: "Body Cocoon", minutes: "90", price: "80" },
    { treatment: "Leg compression", minutes: "60", price: "40" },
    { treatment: "Foot massage", minutes: "25 - 55", price: "25 - 40" },
    { treatment: "Sole reflexology", minutes: "30", price: "35" },
    { treatment: "Slimming /RF/Cavitation/", minutes: "Package pricing", price: null }
];
